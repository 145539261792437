<template>
  <section class="xa-view xa-container">
    <AppLineLoading v-show="isLoading" />
    <div class="xa-cell">
      <div class="xa-flex xa-txt-24" style="line-height:40px">统计</div>
    </div>
    <section>
      <el-row :gutter="8" class="data_overview_row">
        <el-col
          :span="6"
          v-for="(item, index) in overview"
          :key="index"
          class="data_overview_card"
        >
          <el-card shadow="hover" @click.native="itemClick(item)">
            <div
              style="line-height:1.7"
              class="xa-txt-center xa-txt-14 xa-txt-secondary"
            >
              <div
                class="xa-txt-secondary xa-cell"
                style="justify-content: center;"
              >
                <i
                  class="iconfont xa-txt-20"
                  :class="item.icon"
                  style="margin-right:4px;"
                ></i>
                {{ item.label }}
              </div>
              <div class="xa-txt-24 xa-txt-primary">
                {{ item.value }}
              </div>
              <div
                class="xa-cell data_overview_cell"
                v-for="dis in item.items"
                :key="dis.key"
              >
                <label>{{ dis.label }}</label>
                <p :class="dis.up ? 'xa-txt-green' : 'xa-txt-red'">
                  {{ dis.value }}%
                </p>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </section>
    <section class="xa-flex xa-bg-white" v-show="!isLoading">
      <SimpleTable :configPromise="configPromise" style="height:100%" />
    </section>
  </section>
</template>
<script>
import SimpleTable from '@/components/xa-table/simple-table'
export default {
  components: {
    SimpleTable
  },
  data() {
    return {
      isLoading: true,
      configPromise: null,
      overview: []
    }
  },
  methods: {
    itemClick(item) {
      const { link } = item
      if (link) this.$gotoUrl(link)
    }
  },
  created() {
    this.configPromise = this.$actionByRoute()
    this.configPromise.then(data => {
      if (data.overview === undefined) {
        this.overview = [
          {
            icon: 'icon-aiwofei',
            label: '飞机数量',
            value: '210',
            link: './#/home/voucher/coupon/form/distribution/batch',
            items: []
          },
          {
            icon: 'icon-jibennongtiantiqu',
            label: '降落架次',
            value: '19027',
            link: './#/home/voucher/coupon/form/distribution/batch',
            items: []
          },
          {
            icon: 'icon-jinfeiqu',
            label: '作业人数',
            value: '4356',
            link: './#/home/voucher/coupon/form/distribution/batch',
            items: []
          },
          {
            icon: 'icon-lishi',
            label: '人均停留时长',
            value: '533.9410',
            link: './#/home/voucher/coupon/form/distribution/batch',
            items: []
          }
        ]
      } else {
        this.overview = data.overview
      }
      this.isLoading = false
    })
  }
}
</script>
<style scoped lang="scss">
.data_overview_row {
  margin-bottom: 8px;
}
.data_overview_card {
  margin: 2px 0;
  /deep/ .el-card__body {
    padding: 16px 8px;
  }
}
.data_overview_cell {
  justify-content: center;
  & > label {
    flex-basis: 1.5em;
  }
  & > p {
    flex-basis: 5em;
  }
}
</style>

<template>
  <el-container class="simple-table">
    <AppLineLoading v-show="isLoading" />
    <el-main class="simple-table__main">
      <xa-table
        v-if="table.tableHeader.length"
        v-bind="table"
        :selection="false"
      />
    </el-main>
    <el-footer class="simple-table__footer" height="initial">
      <Pagination
        v-bind="pagination"
        @sizechange="handleSizeChange"
        @curchange="handleCurrentChange"
        @refresh="fetchTableData"
      />
    </el-footer>
  </el-container>
</template>
<script>
import XaTable from '@/components/xa-table/table/Index'
import Pagination from '@/components/xa-table/Pagination'
export default {
  components: {
    XaTable,
    Pagination
  },
  data() {
    return {
      isLoading: true,
      table: {
        tableHeader: [],
        tableData: []
      },
      // 分页相关
      tableDataUrl: '',
      pagination: {
        curPage: 1,
        total: null,
        pageSize: 50,
        pageSizes: [50, 100]
      }
    }
  },
  props: {
    configPromise: {
      type: Promise
    },
    src: {
      type: String,
      default: ''
    },
    params: {
      type: [String, Object, Array],
      default() {
        return {}
      },
      validator(val) {
        window.console.log('validator:val', val)
        return val
      }
    }
  },
  methods: {
    handleSizeChange(size) {
      this.pagination.pageSize = size
      this.fetchTableData()
    },
    handleCurrentChange(page) {
      this.pagination.curPage = page
      this.fetchTableData()
    },
    async fetchTableData() {
      this.isLoading = true
      const params = {
        page_index: this.pagination.curPage,
        page_size: this.pagination.pageSize
      }
      const { pagination, tableData } = await this.$diyAction(
        this.tableDataUrl,
        params
      )
      Object.assign(this.pagination, pagination)
      this.table.tableData = tableData
      this.isLoading = false
    },
    init() {
      if (this.configPromise) return this.configPromise
      return this.$diyAction(this.src, this.params)
    }
  },
  async mounted() {
    const { tableHeader, tableDataUrl } = await this.init()
    this.table.tableHeader = tableHeader
    this.tableDataUrl = tableDataUrl
    this.fetchTableData()
  }
}
</script>
<style lang="scss" scoped>
.simple-table {
  position: relative;
  &__main,
  &__footer {
    padding: 4px 8px;
  }
}
</style>
